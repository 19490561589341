<template>
  <v-container>
    <v-sheet
      flat
      max-width="200px"
    >
      <v-switch
        inset
        v-model="goal.enabled"
        @change="fieldChange('enabled', $event)"
        label="Show Goal"
      />
      <v-text-field
        outlined
        dence
        label="Target Amout"
        placeholder="Enter total amount to be raised"
        v-model="goal.target_amount"
        type="number"
        prefix="$"
        @change="fieldChange('target_amount', $event)"
        :disabled="!goal.enabled"
      />
      <v-text-field
        outlined
        dence
        label="Starting Amout"
        placeholder="Enter amount to show having started with"
        v-model="goal.starting_amount"
        type="number"
        prefix="$"
        @change="fieldChange('starting_amount', $event)"
        :disabled="!goal.enabled"
      />
    </v-sheet>

    <our-progress
      :goal="goal"
      :disabled="!goal.enabled"
    />

  </v-container>
</template>

<script>
import OurProgress from './Progress.vue'

export default {
  components: { OurProgress },
  props: ['item'],
  methods: {
    async fieldChange(f, v) {
      let product = await this.api.post('/product/module', {
        name: 'goal',
        productID: this.item._id,
        data: {[f]: v}
      })
      console.log('field changed', {product, f, v})
      this.$emit('refresh')
    }
  },
  computed: {
    goal(){return this.item.goal || {}},
  }
}
</script>