<template>
  <v-progress-linear
    :active="!!goal.target_amount"
    height="20"
    rounded
    striped
    :value="(goal.starting_amount / goal.target_amount) * 100"
    color="light-blue"
    :disabled="disabled"
  />
</template>

<script>
export default {
  props: ['goal', 'disabled']
}
</script>