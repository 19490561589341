<template>
  <div>
    <v-card
      class="d-flex flex-wrap flex-row mb-6 justify-end"
      flat
      tile
      color="transparent"
    >
      <v-card-title>
        <v-btn
          icon
          @click="newProduct()"
        >
          <v-icon>mdi-plus-circle</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="d-flex flex-wrap justify-center">
        <product-button
          v-for="(item) in items"
          :key="item._id"
          :item="item"
          @id="inspect"
        />
      </v-card-text>
    </v-card>

    <product 
      :shop-context="shopContext"
      :id="id"
      :show="showProduct"
      @close="closeProduct"
    />

  </div>
</template>

<script>
import ProductButton from './Button'
import Product from './product/Index'

export default {
  components:{
    ProductButton,
    Product
  },
  props: {
    shopContext: {
      type: String,
      default: ()=>null
    },
  },
  data(){
    return {
      items: [],
      id: null,
      showProduct: false
    }
  },
  sockets:{
    FETCH_DATA_FOR_SHOP(data){
      this.items = data.products
    }
  },
  watch:{
    shopContext(){
      this.showProduct = false
      this.fetch()
    }
  },
  mounted(){
    this.fetch()
  },
  methods: {
    async fetch(){
      if (this.shopContext) {
        this.items = await this.api.get(`/product/productsforshop/${this.shopContext}`)
      }
    },
    inspect(v){
      this.id = v
      this.showProduct = false
      this.showProduct = true
    },
    async closeProduct(){
      this.showProduct = false
      this.id = null
      await this.fetch()
    },
    async newProduct(){
      let product = await this.api.post(`/product/create/${this.shopContext}`)
      console.log(product)
      this.fetch()
      this.inspect(product._id)
    }
  }
}
</script>
