<template>
  <v-container fluid>

    <v-main fluid>

      <shop-tabs
        :loaded="loaded"
        @context="changeContext"
        v-if="auth.set"
      />
    
      <v-container fluid
        v-if="auth.set"
      >
        <edit-shop
          v-if="footerNav.name=='settings'"
          :shop_context="shopTab"
          @reloaded="reloaded"
        />
        <edit-market
          v-if="footerNav.name=='products'"
          :shop-context="shopTab._id"
        />
        <edit-orders
          v-if="footerNav.name=='orders'"
        />
        <contacts
          v-if="footerNav.name=='contacts'"
          :shop-context="shopTab._id"
        />
      </v-container>

    </v-main>

    <new-stuff v-if="auth.set"
      :shop_context="shopTab"
    />

    <shop-footer
      v-if="auth.set"
      @change="changeFooterNav"
    />

  </v-container>
</template>

<script>
import NewStuff from './NewStuff'
import ShopTabs from './ShopTabs'
import EditShop from './shop/Index'
import EditMarket from './market/Index'
import EditOrders from './EditShopOrders'
import ShopFooter from './ShopFooter'
import Contacts from './contacts/Index'

export default {
  components: {
    ShopTabs,
    EditShop,
    EditMarket,
    EditOrders,
    ShopFooter,
    NewStuff,
    Contacts
  },
  data(){
    return {
      shopTab: {},
      loaded: Date.now(),
      footerNav: {}
    }
  },
  methods:{
    changeFooterNav(v){this.footerNav = v},
    changeContext(v){
      this.shopTab = v
    },
    reloaded(){
      this.loaded = Date.now()
    }
  }
}
</script>