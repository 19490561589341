<template>
  <v-container>
    <v-sheet
      color="grey lighten-4"
      rounded
      class="pa-5"      
    >
      <v-row dense no-gutters>
        <v-col cols="2">
          <v-icon>mdi-mailbox</v-icon>
        </v-col>
        <v-col cols="10">
          <v-row dense no-gutters>
            <v-col cols="12">{{ship.name}}</v-col>
            <v-col cols="12" v-if="ship.address1">{{ship.address1}}</v-col>
            <v-col cols="12" v-if="ship.address2">{{ship.address2}}</v-col>
            <v-col cols="12">{{ship.city}}, {{ship.state}} {{ship.zip}}</v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-sheet>
  </v-container>
</template>

<script>
export default {
  props: ['ship'],
  computed: {
    prettyAddress(){
      return [
        this.ship.name,
        this.ship.address1,
        this.ship.address2,
        [this.ship.city, ',', this.ship.state, this.ship.zip].join('')
      ].join('/n')
    }
  }

}
</script>