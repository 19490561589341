<template>
  <v-sheet
    max-width="130px"
    class="ma-0 pa-0"
  >

    <v-badge
      offset-x="10"
      offset-y="10"
      color="red lighten-2"
      v-if="!custom"
      :value="!disabled"
    >
      <template v-slot:badge>
        <div
          @click="$emit('delete', idx)"
          :disabled="disabled"
          class="ma-0 pa-0"
        >
          <v-icon small>
            mdi-delete
          </v-icon>
        </div>
      </template>
      <v-text-field
        v-if="!custom"
        flat
        solo
        :value="price.amount"
        dark
        :background-color="!price.enabled || disabled ? 'green lighten-2' : 'green'"
        :disabled="!price.enabled || disabled"
        prepend-icon="mdi-cancel"
        @change="$emit('change', {idx, amount: $event})"
      />
    </v-badge>

    <v-text-field
      v-if="custom"
      flat
      solo
      value="Custom"
      dark
      :background-color="disabled ? 'green lighten-2' : 'green'"
      :disabled="disabled"
      prepend-icon="mdi-cancel"
    />

  </v-sheet>
</template>

<script>
export default {
  props: ['price', 'disabled', 'idx', 'custom']  
}
</script>