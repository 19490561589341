<template>
  <v-container>

    <v-select
      :items="stripe.currencies"
      outlined
      @change="fieldChange('currency', $event)"
      :value="(item.properties||{}).currency"
      :rules="rules.currency"
    />

    <v-text-field
      outlined
      dence
      label="Price"
      placeholder="Good Price"
      v-model="(item.properties||{}).price"
      type="money"
      prefix="$"
      @change="fieldChange('price', $event)"
    />

    <v-checkbox
      label="Show Quantity"
      :ripple="false"
      :input-value="properties.show_quantity"
      @change="fieldChange('show_quantity', $event)"
    />

    <colors
      :colors="(item.properties||{}).colors || []"
      @change="colorsChange"
    />

    <sizes
      :productID="item._id"
      :sizes="(item.properties||{}).sizes || []"
      @change="sizesChange"
    />
    <v-text-field
      v-model="(item.properties||{}).external_link"
      outlined
      label="External Link"
      placeholder="Link to an external resource"
      prepend-icon="mdi-open-in-new"
      class="mt-3"
      @change="fieldChange('external_link', $event)"
    ></v-text-field>
  </v-container>
</template>

<script>
import colors from './Colors'
import sizes from './Sizes'

export default {
  name: 'ProductProperties',
  props: {
    shopContext: {
      type: String,
      default: ()=>null
    },
    item: {
      type: Object,
      default: ()=>{return {}}
    }
  },
  components: {
    colors,
    sizes,
  },
  
  data(){
    return {
      stripe: {},
      rules: {
        currency: [
          v => !!v || 'Currency is required',
        ],
      }
    }
  },
  mounted(){this.fetch()},
  watch:{shopContext(){this.fetch()}},
  computed: {
    properties(){return this.item.properties || {}}
  },

  methods: {
    colorsChange(v){
      this.fieldChange('colors', v)
    },
    sizesChange(v){
      this.fieldChange('sizes', v)
    },
    async fieldChange(f, v){
      await this.api.post('/product/module', {
        name: 'properties',
        productID: this.item._id,
        data: {[f]: v}
      })
      this.$emit('refresh')
    },

    async fetch(){
      this.stripe = await this.api.get(`/stripe/status/${this.shopContext}`)
    }
  }

}
</script>