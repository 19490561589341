<template>
  <v-container
    fluid
    class="d-flex flex-column justify-center ma-0 pa-0"
  >

    <v-container
      fluid
      class="d-flex flex-row justify-space-around ma-0 pa-0"
    >
      <v-card
        v-for="(mode, n) in stripe.modes"
        :key="n"
        max-width="100"
        flat
        class="ma-0 pa-0"
        :disabled="disabled"
      >
        <v-badge
          :content="mode.name"
          :color="mode.ready ? 'primary' : 'grey lighten-2'"
          overlap
          offset-x="12"
          offset-y="16"
          bordered
          left
          class="d-flex flex-column"
        >
          <v-chip 
            v-for="(status, type) in mode.status"
            :key="type"
            :outlined="!status"
            :disabled="!status"
            class="ma-1"
            color="green"
          >
            <v-icon left>mdi-key</v-icon>
            {{type}}
          </v-chip>
        </v-badge>
      </v-card>
    </v-container>

    <stripe-add-key
      @add-key="sendStripeKey"
      :disabled="disabled"
    />

    <are-you-sure
      :opt="dialog"
      @clickedNo="dialog.show=false"
      @clickedYes="dialog.show=false; toggleMode(modeCandidate)"
    />
  </v-container>
</template>

<script>
import StripeAddKey from './StripeAddKey'

export default {
  props: {
    disabled: {
      type: Boolean,
    },
    shopID: {
      type: String,
      default: ()=>''
    },
    stripe: {
      type: Object,
      default: ()=>{return {}}
    },
    newAccountName: {
      type: String,
      default: ()=>''
    }
  },
  components: {StripeAddKey},
  data(){
    return {
      modeCandidate: null,
      activeMode: null,
      busy: false,
      dialog: {
        show: false,
        text: {}
      },
    }
  },
  computed:{
    modeIndex(){
      return this.stripe.modes
        && this.stripe.modes.findIndex(m=>m.name===this.stripe.mode)
    }
  },
  watch:{shopID(){this.refreshControl()}},
  mounted(){this.refreshControl()},
  methods:{
    refreshControl(){
      this.activeMode = this.modeIndex
    },
    toggleModeConfirm(v){
      if (v != this.activeMode) {
        this.modeCandidate = v
        this.dialog.text.title = v==1 ? 'Going live...' : 'Returning to test mode...'
        this.dialog.text.subTitle = null
        this.dialog.show = true
      }
    },
    async toggleMode(v){
      this.activeMode = v
      await this.api.post('/shop/module',{
        name: 'stripe',
        shopID: this.shopID,
        data: {mode: this.stripe.modes[v].name}
      })
      this.$emit('refresh')
    },
    async sendStripeKey(token){
      this.busy = true
      await this.api.post('/stripe/token', {
        token: token,
        saveUserToken: true,
        accountName: this.stripe.accountName || this.newAccountName
      })
      this.busy = false
      this.$emit('refresh')
    }
  }
}
</script>