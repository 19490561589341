<template>
  <v-carousel>
    <v-carousel-item
      v-for="img in images"
      :key="img"
      :src="`${img}`"
      height="200px"
    >
      <v-container>
        <v-row align="center">
          <v-col align="end">
            <v-btn
              color="blue darken-2"
              dark
              fab
              v-on:click="confirmDeleteImage(img)"
            >
              <v-icon x-large>mdi-delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-carousel-item>

    <are-you-sure
      :opt="dialog"
      @clickedNo="dialog.show = false"
      @clickedYes="dialog.show = false; deleteImage()"
    />

  </v-carousel>
</template>

<script>
export default {
  name: 'ShopProductImages',
  props: {
    productId: {
      type: String,
      default: ()=>null
    },
    images: {
      type: Array,
      default: ()=>[]
    }
  },
  data: ()=>{
    return {
      dialog: {
        text: {},
        show: false
      },
      imageNameForDelete: null
    }
  },
  methods: {
    confirmDeleteImage(imageName) {
      this.imageNameForDelete = imageName.split('/').slice(-2).join('/')
      this.dialog.text.title = 'Delete this image...'
      this.dialog.show = true;
    },
    async deleteImage(){
      if (this.imageNameForDelete && this.productId) {
        await this.api.delete(`/product/image/${this.productId}/${this.imageNameForDelete}`)
        this.imageNameForDelete = null
        this.$emit('refresh')
      }
    },
  }
}
</script>