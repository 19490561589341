<template>
  <v-card
    flat
    class="ma-0 pa-0 rounded"
    color="transparent"
  >
    <v-card-title>
      Shippo Parcel
    </v-card-title>
    <v-text-field
      v-model="data.length"
      outlined
      label="length"
      type="number"
      @change="update"
    />
    <v-text-field
      v-model="data.width"
      outlined
      label="width"
      type="number"
      @change="update"
    />
    <v-text-field
      v-model="data.height"
      outlined
      label="height"
      type="number"
      @change="update"
    />
    <v-select
      v-model="data.distance_unit"
      :items="distance_units"
      outlined
      label="unit"
      @change="update"
    />
    <v-text-field
      v-model="data.weight"
      outlined
      label="weight of 1 item"
      type="number"
      @change="update"
    />
    <v-select
      v-model="data.mass_unit"
      :items="mass_units"
      outlined
      label="unit"
      @change="update"
    />
    <v-text-field
      v-model="data.items_per_parsel"
      outlined
      label="# of items allowed in a parcel"
      type="number"
      @change="update"
    />
  </v-card>
</template>

<script>
import { props } from './componentProps'

export default {
  name: 'Shippo',
  props,
  data() {
    return {
      distance_units: [
        "cm",
        "in",
        "ft",
        "mm",
        "m",
        "yd",
      ],
      mass_units: [
        "g",
        "oz",
        "lb",
        "kg",
      ],
      data: {}
    }
  },
  watch: {
    shipping() {
      this.data = this.shipping.shippo || {}
    }
  },
  methods: {
    update(){
      const shippo = this.data
      this.$emit('update', {shippo})
    }
  },
}
</script>