<template>
  <v-expansion-panel>
  <expansion-panel-header
    title="Discord"
    icon="mdi-discord"
    :isGood="isGood"
  />
  <v-expansion-panel-content>
    <v-text-field
      label="Alerts"
      outlined
      dense
      v-model="dc.alerts"
      @change="update"
    />
  </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import ExpansionPanelHeader from './ExpansionPanelHeader'
import { props } from './helper'

export default {
  components: {
    ExpansionPanelHeader,
  },
  props,
  data() {
    return {
      dc: {}
    }
  },
  watch: {
    shopId() {
      this.dc = this.shop.discord || {}
    }
  },
  computed:{
    isGood(){
      return !!(this.dc && this.dc.alerts)
    }
  },
  methods:{
    async update(){
      const discordStuff = {
        name: 'discord',
        shopID: this.shop._id,
        data: this.dc
      }
      const serverSaid = await this.api.post('/shop/module', discordStuff)
      console.log('did i fire?', {discordStuff, serverSaid})
      this.$emit('refresh')
    }
  }
}
</script>

