<template>
  <v-expansion-panel>
    <expansion-panel-header
      title="Shop Properties"
      icon="mdi-store"
      :isGood="true"
      :gage="shop.livemode ? '$openSign' : '$closedSign'"
      :gageSize="40"
      :gageColor="shop.livemode ? 'primary' : 'secondary'"
    />
    <v-expansion-panel-content
      fluid
      class="pa-0 ma-0"
    >
        <v-row>
          <v-col cols="5">
            <v-text-field
              persistent-hint
              hint="URI of the shop"
              v-model="uri"
              :disabled="!edit"
              prefix="/"
              @keyup="testUri(uri)"
              @change="validateUri"
              :append-outer-icon="
                isUriOk ? 'mdi-account-check' : 'mdi-account-alert'
              "
            />
          </v-col>
          <v-col cols="5">
            <v-text-field
              persistent-hint
              hint="Organization Name"
              v-model="shop.organization_name"
              @change="fieldChange('organization_name', $event)"
              :disabled="!edit"
            />
          </v-col>
          <v-col cols="2" align-self="center" align="right" class="d-inline">
            <v-btn icon color="red" @click="$emit('delete-shop')">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-col>
          <div class="pa-3">
            Shop ID: 
            <code class="pa-3">
              {{shopId}}
            </code>
          </div>
        </v-row>

        <v-row>
          <v-col cols="0">
            <v-text-field
              persistent-hint
              hint="Owners Name"
              v-model="shop.owners_name"
              @change="fieldChange('owners_name', $event)"
              :disabled="!edit"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="0">
            <v-text-field
              persistent-hint
              hint="Email"
              v-model="shop.email"
              @change="fieldChange('email', $event)"
              :disabled="!edit"
            />
          </v-col>
          <v-col>
            <v-text-field
              persistent-hint
              hint="Phone"
              v-model="shop.phone"
              @change="fieldChange('phone', $event)"
              :disabled="!edit"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              persistent-hint
              hint="Address"
              v-model="shop.address"
              auto-grow
              row-height="18"
              rows="2"
              @change="fieldChange('address', $event)"
              :disabled="!edit"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-checkbox
          label="Include Shop in the Market Index"
          v-model="shop.includeInMarketIndex"
          color="red"
          @change="fieldChange('includeInMarketIndex', $event)"
        />
        <v-col class="d-flex flex-row pa-0 my-5 justify-end">
          <v-switch
            v-model="shop.livemode"
            dense
            inset
            align="right"
            @change="toggleLivemode"
            :disabled="!edit"
          />
          <div
            class="my-auto mr-2"
            :style="`color: ${shop.livemode ? '#2196F3' : '#F44336'};`"
          >
            Shop is {{ shop.livemode ? "activated" : "deactivated" }}
          </div>
          <v-icon :color="`${shop.livemode ? 'primary' : 'error'}`">{{
            shop.livemode ? "mdi-check" : "mdi-cancel"
          }}</v-icon>
        </v-col>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import ExpansionPanelHeader from './ExpansionPanelHeader.vue'
import { props } from './helper'

export default {
  components: { ExpansionPanelHeader },
  props,
  data(){
    return {
      edit: true,
      isUriOk: true,
      uri: null
    }
  },
  mounted(){this.uri = this.shop.uri},
  watch:{
    shop(v){this.uri = v.uri}
  },
  methods:{
    async testUri(v) {
      if (v.length >= 1 && v != this.shop.uri) {
        const shop = await this.api.get(`/shop/shop/${v}`)
        this.isUriOk = !shop
      }
    },
    validateUri() {
      if (!this.isUriOk) {
        // reset
        this.uri = this.shop.uri;
      } else if (this.shop.uri != this.uri) {
        this.fieldChange('uri', this.uri)
      }
    },
    async fieldChange(f, v){
      await this.api.put(`/shop/update/${this.shop._id}`, {[f]:v})
    },
    async toggleLivemode(v){
      await this.fieldChange('livemode', v)
      this.$emit('reloaded')
    }
  }
}
</script>