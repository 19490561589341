<template>
  <v-expansion-panel>
  <expansion-panel-header
    title="Mailchimp"
    icon="mdi-at"
  />
  <v-expansion-panel-content>
    <v-card
      class="d-flex flex-row justify-center"
      color="transparent"
    >
    <v-btn
      text
    >
      <v-icon left>mdi-account-multiple</v-icon>
      {{ list.stats && list.stats.member_count }}
    </v-btn>
    <v-btn
      text
    >
      <v-icon left>mdi-account-multiple-remove</v-icon>
      {{ list.stats && list.stats.unsubscribe_count }}
      
    </v-btn>
    </v-card>
    <v-text-field
      label="API Key"
      outlined
      dense
      v-model="mc.api_key"
      @change="updateMailchimp"
      :type="show ? 'text' : 'password'"
      :append-icon="show ? 'mdi-eye-off' : 'mdi-eye'"
      @click:append="show = !show"
    />
    <v-text-field
      label="Audience ID"
      outlined
      dense
      v-model="mc.list_id"
      @change="updateMailchimp"
    />
    <v-text-field
      label="Server Prefix"
      hint="log into your Mailchimp account and look at the URL in your browser. You’ll see something like https://us19.admin.mailchimp.com/ — the us19 part is the server prefix. Note that your specific value may be different."
      outlined
      dense
      v-model="mc.server_prefix"
      @change="updateMailchimp"
      persistent-hint
    />
  </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import ExpansionPanelHeader from './ExpansionPanelHeader'
import { props } from './helper'

export default {
  name: 'Mailchimp',
  components: {
    ExpansionPanelHeader,
  },
  props,
  data(){
    return {
      show: false,
      list: {}
    }
  },
  computed:{
    mc(){return (this.shop.mailchimp||{})},
    isGood(){
      return !!this.mc.server_prefix
        && !!this.mc.api_key
        && !!this.mc.list_id
    }
  },
  mounted () {
    this.fetch();
  },
  watch: {
    shopId() {
      this.fetch();
    }
  },
  methods:{
    async updateMailchimp(){
      await this.api.post('/shop/module', {
        name: 'mailchimp',
        shopID: this.shop._id,
        data: this.mc
      })
      this.$emit('refresh')
    },
    async fetch(){
      if (this.shop && this.shop._id) {
        this.list = await this.api.get(`/mailchimp/list/${this.shop._id}`)
      }
    }

  }
}
</script>

