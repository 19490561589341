import { demo } from '@/helper'

const mutations = {
  darkmode: (state, mode) => {
    state.darkmode = mode
  },

  resetState: (state) => {
    state.purgeClientData = true
    console.log('resetState...')
  },

  shopTab(state, idx) {
    state.shopTab = idx
  },

  SOCKET_INITIALIZE_BASE(state, data){
    state.stripe_currencies = data.stripe_currencies
    state.stripe_intervals = data.stripe_intervals
  },

  SOCKET_FETCH_SHOPS(state, shopsArray){
    let slug = shopsArray.reduce((acc, c)=>{
      acc[c._id] = c
      return acc
    },{})

    state.shops = {...slug}
  },

  /*eslint-disable no-unused-vars*/
  SOCKET_HYDRATE_PRODUCT(state, data){
    let productSlugID = data._id || data.productID
    let {productID, shopID, repeatOnClient, ...upateData} = data
    if (productSlugID && shopID) {
      let productSlug = {}
      let shopSlug = {}
      productSlug[productSlugID] = {...state.products[shopID][productSlugID], ...upateData}
      shopSlug[shopID] = {...state.products[shopID], ...productSlug}
      state.products = {...state.products, ...shopSlug}
    }
  },
  /*eslint-enable no-unused-vars*/

  hydrate_stripe_prices(state, data){
    let product = state.products[state.shopID][state.productID]
    if (!product.subscription) product.subscription = {}
    product.subscription.stripe_prices = data;
  },

  hydrateSubscription(state, data){
    let product = state.products[state.shopID][state.productID]
    if (!product) {
      console.warn('you are hydrating without a product...', data);
      return
    }
    if (!product.subscription) product.subscription = {}
    product.subscription = {...product.subscription, ...data}
  },

  SOCKET_SHOP_CREATE(state, opt){
    let slug = {}
    slug[opt.data._id] = opt.data
    state.shops = {...state.shops, ...slug}
    if (opt.editAfter) {
      state.shopID = opt.data._id
      state.editShop = true
    }
  },

  SOCKET_SHOP_DELETE(state, deletedShop){
    let slug = state.shops
    delete slug[deletedShop._id]
    state.shops = {...slug}
    state.shopID = null
  },

  setProductID(state, data){
    state.productID = data
  },

  SOCKET_FETCH_ORDERS(state, data){
    state.orders = data || []
  },
  
  initializeStateStuff(state, data){
    state.isDemo = !demo.hide
    state.routeMeta = data
  },
  
  SOCKET_DELETE_PRODUCT(state, opt){
    let products = state.products[opt.shopID]
    if (products && products[opt.productID]) {
      let shopSlug = {}
      delete products[opt.productID]
      shopSlug[opt.shopID] = products
      state.products = {...state.products, ...shopSlug}
    }
  },
  
  M_CREATE_PRODUCT(state, opt){
    let productSlug = {}
    let shopSlug = {}
    productSlug[opt.data._id] = opt.data
    shopSlug[opt.shopID] = {...productSlug, ...state.products[opt.shopID]}
    state.products = {...state.products, ...shopSlug}
  },


  file_uploaded_over_api(state, data){
    state.products[data.shopID][data.productID].images.push(data.imageName)
  },

  SOCKET_SNACKBAR(state, data){
    state.snackbar.timeout = data.timeout || 3000
    state.snackbar.text = data.message || 'unknown message'
    state.snackbar.show = true
  },

  SOCKET_HYDRATE_BASE_SHOP(state, data){
    let {shopID, _id, ...rest} = data || {}
    state.shops[shopID || _id] = {...state.shops[shopID || _id], ...rest}
  },

  assignState_pullImg(state, data){
    let prodIdx = state.products.map(i=>i._id).indexOf(data.productID)
    if (prodIdx > -1) {
      let imgIdx = state.products[prodIdx].images.indexOf(data.imageName)
      if (imgIdx > -1) {
        state.products[prodIdx].images.splice(imgIdx, 1)
      }
    }
  },

  assignState_stripeSessionId(state, data){state.stripe_session_id = data},  

  editProductDialog(state, data){state.editProductDialog = data},

  shops(state, data){state.shops = data},
}

export default mutations
