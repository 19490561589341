<template>
  <v-alert
    type="success"
    icon="mdi-cigar"
    :value="!disabled"
    class="ma-5"
  >
    Your {{ status }} is empty.
  </v-alert>
</template>

<script>
export default {
  props: {
    status: {
      type: String,
      default: ()=>null
    },
    disabled: {
      type: Boolean
    }
  },
}
</script>