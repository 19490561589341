<template>
  <v-speed-dial
    id="speedDial"
    absolute
    v-model="fab"
    bottom left
    direction="top"
    transition="slide-y-reverse-transition"
  >
    <template v-slot:activator>
      <v-btn
        v-model="fab"
        color="primary"
        dark
        fab
        small
      >
        <v-icon v-if="fab">
          mdi-close
        </v-icon>
        <v-icon v-else>
          mdi-plus
        </v-icon>
      </v-btn>
    </template>
    
    <v-btn
      id="testGrp"
      fab
      dark
      small
      color="green"
      @click="newShop"
    >
      <v-icon>mdi-store</v-icon>
    </v-btn>

    <v-btn
      id="testGrp"
      fab
      dark
      small
      color="green"
      @click="newProduct"
    >
      <v-icon>mdi-tshirt-crew</v-icon>
    </v-btn>

  </v-speed-dial>
</template>

<script>
  export default {
    props: ['shop_context'],
    
    data: ()=>{
      return {
        fab: false,
      }
    },

    methods: {
      newShop(){
        this.$store.dispatch('base/newStuff_shop') 
      },

      newProduct(){
        this.$store.dispatch('base/newStuff_product', this.shop_context._id) 
      }
    }
  }
</script>

<style scoped>
#speedDial {
  position: fixed;
  z-index: 4;
}
#testGrp {
    text-decoration: none !important;
    color: white;
}

</style>