const getters = {

  darkmode: state => {
    return state && state.darkmode
  },

  shop: state => {
    return state.shops[state.shopID] || {}
  },

  shops: state => {
    return state && state.shops || []
  },

  shopList: state => {
    return Object.values(state.shops)
  },

  shopTabIndex: (state) => {
    return Object.keys(state.shops).indexOf(state.shopID)
  },

  shopTab: state => {
    return state && state.shopTab || 0
  },

  product: state => productID => {
    let slug = {
      shopID: state.shopID,
      productID: productID || state.productID
    }
    return {...slug, ...(state.products[slug.shopID]||[])[slug.productID] || {}}
  },
  
  productType: (state) => {
    return (state.products[state.shopID][state.productID]||{}).type;
  },

  shopStripeCurrencies: state => {
    return (state.shops[state.shopID]||{}).stripe_currencies
  },
  
  productColors: (state, getters) => {
    return ((getters['product'](state.productID)||{}).properties||{}).colors || []
  },
  
  productSizes: (state, getters) => {
    return ((getters['product'](state.productID)||{}).properties||{}).sizes || []
  },

  stripePrices: (state, getters) => {
    return (((getters.product(state.productID)||{}).subscription||{}).stripe_prices || []).filter(r => {
      return r.active
    }).map(r=>{
      r.desc = `$ ${(r.unit_amount + 0).toFixed(2) * .01} ${r.currency} / ${r.recurring.interval}`
      return r;
    });
  },

  snackbar: state => {
    return state && state.snackbar
  },
}

export default getters