<template>
  <v-card
    :key="item._id"
    class="pa-0 ma-1 rounded"
    tile
    ripple
    elevation="0"
    @click="$emit('id', item._id)"
  >
    <v-img
      :src="item.thumbnail ? `${item.thumbnail}` : null"
      class="white--text align-end"
      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
      :width="width"
      aspect-ratio="1"
      position='center'
    >
    <v-row no-gutters>
      <v-col align="start">
        <v-icon small top class="pa-1"
          color="white"
        >{{icon(item.type).icon}}</v-icon>
      </v-col>
      <v-col align="end">
        <v-icon small top class="pa-1"
          :color="item.active ? 'white' : 'grey'"
        >mdi-check-circle</v-icon>
      </v-col>
    </v-row>
    <v-row><v-col><v-spacer></v-spacer></v-col></v-row>
    <v-row no-gutters>
      <v-col>
        <v-card-title
          v-text="item.name"
          class="text-body-1 text-no-wrap font-weight-bold"
        ></v-card-title>
      </v-col>
    </v-row>
    </v-img>
  </v-card>
</template>

<script>
export default {
  props:['item'],
  computed: {
    width(){
      let size = this.$vuetify.breakpoint.name
      return size == 'xs' ? 110 : 250
    }
  },
  methods:{
    icon(v){
      return {
        product: {icon: 'mdi-tshirt-crew'},
        subscription: {icon: 'mdi-repeat'},
        goal: {icon: 'mdi-thermometer'},
        active: {icon: 'mdi-check-circle', color: 'green'},
        inactive: {icon: 'mdi-cancel', color: 'red'},
      }[v] || {}
    }
  }
}
</script>