<template>
    <v-expansion-panel>
      <expansion-panel-header
          title="User Details"
          icon="mdi-account"
          :badgeContent="users.length"
      />
      <v-expansion-panel-content>
        <v-container
          class="d-flex flex-row flex-wrap"
        >
          <user
            v-for="user in users"
            :key="user.username"
            :shop-id="shopId"
            :user="user"
            @remove="confirmRemoveUser"
            @refresh="fetch"
          />
        </v-container>
        <v-row dense>
        <v-text-field 
          v-model="usernameOrEmail"
          outlined
          dense
          label="User"
          hint="Enter a username or email of an existing Merchbase user"
          persistent-hint
        >
          <template v-slot:append-outer>
            <v-btn
              color="secondary"
              :disabled="!usernameOrEmail"
              :loading="loadingAdd"
              @click="addUser()"
            >
              add user
            </v-btn>
          </template>
        </v-text-field>
        </v-row>
      </v-expansion-panel-content>

      <are-you-sure
        :opt="dialog"
        @clickedNo="dialog.show=false"
        @clickedYes="dialog.show=false; removeUser()"
      />
    </v-expansion-panel>
</template>

<script>
import ExpansionPanelHeader from '../ExpansionPanelHeader'
import User from './User'
import { props } from '../helper'

export default {
  components: { User, ExpansionPanelHeader },
  props,
  data(){
    return {
      usernameOrEmail: null,
      adduserToggle: null,
      users: [],
      loadingAdd: false,
      loadingRemove: false,
      dialog: {
        show: false,
        text: {
          title: 'Remove User...'
        }
      },
    }
  },
  watch:{
    shopId(){
      this.fetch()
    }
  },
  mounted(){
    this.fetch()
  },
  methods: {
    async fetch(){
      if (this.shopId) {
        this.users = await this.api.get(`/shop/users/${this.shopId}`)
      }
    },

    async addUser(){
      this.loadingAdd = true
      this.users = await this.api.put(`/shop/users/${this.shopId}/${this.usernameOrEmail}`)
      this.usernameOrEmail = null
      this.loadingAdd = false
    },

    confirmRemoveUser(who){
      this.usernameOrEmail = who.username
      this.dialog.show = true
    },

    async removeUser(){
      if (this.usernameOrEmail) {
        this.loadingRemove = true
        this.users = await this.api.delete(`/shop/users/${this.shopId}/${this.usernameOrEmail}`)
        this.usernameOrEmail = null
        this.loadingRemove = false
      }
    },
    
  }

}
</script>