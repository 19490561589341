<template>
  <v-select
    v-model="editSizes"
    :items="availableSizes"
    label="Sizes"
    multiple
    chips
    hint="Sizes available for purchase"
    persistent-hint
    @change="sizesChanged"
  >
    <template v-slot:prepend-item>
      <v-list-item
        ripple
        @click="toggle"
      >
        <v-list-item-action>
          <v-icon :color="sizes.length > 0 ? 'indigo darken-4' : ''">
            {{ icon }}
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            Select All
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2"></v-divider>
    </template>
  </v-select>

</template>

<script>
import {productSizes} from '@/helper'

export default {
  props: ['sizes'],
  data() {
    return {
      availableSizes: productSizes || []
    };
  },
  computed: {
    editSizes: {
      get(){return this.sizes},
      set(){}
    },
    isAllSizes(){return this.availableSizes.length == this.sizes.length},
    isSomeSizes(){return this.sizes.length > 0 && !this.isAllSizes},
    icon () {
      if (this.isAllSizes) return 'mdi-close-box'
      if (this.isSomeSizes) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  },
  methods: {
    sizesChanged(v) {
      if (Array.isArray(this.sizes)) {
        this.$emit('change', v)
      }
    },
    toggle () {
      this.$nextTick(() => {
        if (this.isAllSizes) {
          this.$emit('change', [])
        } else {
          this.$emit('change', this.availableSizes.slice())
        }
      })
    },
  },
}
</script>