import Vue from 'vue';
import Vuetify from 'vuetify/lib';

import Shippo from '../assets/mdi-shippo'
import Doge from '../assets/mdi-doge'
import Xrp from '../assets/mdi-xrp'
import Btc from '../assets/mdi-btc'
import Eth from '../assets/mdi-eth'
import OpenSign from '../assets/mdi-open-sign'
import ClosedSign from '../assets/mdi-closed-sign'
import mbLogo from '../assets/merchbaseLogo'



Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#2196F3',
        secondary: '#DDDDDD',
        accent: '#82B1FF',
        error: '#F44336',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        background: '#FFFFFF'
      },
      dark: {
        background: '#121212',
        secondary: '#424242'
      }
    },
  },
  icons: {
    values: {
      shippo: { component: Shippo },
      doge: { component: Doge },
      xrp: { component: Xrp },
      btc: { component: Btc },
      eth: { component: Eth },
      openSign: { component: OpenSign },
      closedSign: { component: ClosedSign },
      mbLogo: { component: mbLogo }
    }
  }
});
