<template>
  <v-container
    fluid
    class="pa-0 ma-0 d-flex flex-row"
  >
    <v-avatar
      v-if="coin.icon"
      size="50"
    >
      <v-icon
        x-large
        :color="`${coin.enabled? 'primary' : 'grey'}`"
        style="fill: currentColor"
      >
        {{ coin.icon }}
      </v-icon>
    </v-avatar>

    <v-text-field
      outlined
      :label="`${coin.name} wallet address`"
      v-model="coin.address"
      class="mx-4"
      @change="$emit('update', coin)"
    />

    <v-switch
      v-model="coin.enabled"
      :disabled="!isGood"
      @change="$emit('update', coin)"
    />
  </v-container>
</template>

<script>
export default {
  props: {
    coin: {
      type: Object,
      default: ()=>{return {}}
    },
    data: {
      type: Object,
      default: ()=>{return {}}
    }
  },
  computed:{
    isGood(){
      return !!this.coin?.address
    }
  },
}
</script>