<template>
  <v-card
    flat
    color="transparent"
  >
    <v-card-title>
      Free Shipping
    </v-card-title>
    <v-card-subtitle>
      The cost of shipping is included in the price of the product
    </v-card-subtitle>
  </v-card>
</template>

<script>
import { props } from './componentProps'

export default {
  name: 'Free',
  props,
}
</script>