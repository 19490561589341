<template>
  <v-bottom-sheet
    :value="show"
    max-width="700px"
    scrollable
    @click:outside="$emit('close')"
    @keydown.esc="$emit('close')"
  >
    <v-card
      style="max-height: 1500px"
      class="overflow-y-auto"
    >
      <v-card-title>
        <v-row>
          <v-col cols="4">
            <v-text-field
              label="Number"
              v-model="item.number"
              name="title"
              @change="fieldChange('number', $event)"
            />
          </v-col>

          <v-col cols="6">
            <v-text-field
              label="Name"
              v-model="item.name"
              name="title"
              @change="fieldChange('name', $event)"
            />
          </v-col>

          <v-col cols="2" align="end">
            <v-switch
              x-large
              inset
              v-model="item.active"
              hint="Item Status"
              color="primary"
              @change="fieldChange('active', !!item.active)"
            />
          </v-col>
        </v-row>

      </v-card-title>

      <v-card-text>

        <v-textarea
          label="Description"
          id="description"
          v-model="item.description"
          name="description"
          @change="fieldChange('description', $event)"
          rows="3"
        />

        <v-combobox
          v-model="item.tags"
          label="tags"
          clearable
          hide-selected
          multiple
          outlined
          persistent-hint
          chips
          deletable-chips
          @change="fieldChange('tags', $event)"
        />

        <v-btn-toggle
          v-model="productTab"
          mandatory
          rounded
          borderless
        >
          <v-btn
            v-for="tab in tabs"
            :key="tab.name"
            :value="tab.name">
            <span class="hidden-sm-and-down">{{ tab.name }}</span>
            <v-icon>{{ tab.icon }}</v-icon>
          </v-btn>
        </v-btn-toggle>
        
        <v-divider></v-divider>

        <v-tabs-items v-model="productTab">
          <v-tab-item
            v-for="tab in tabs"
            :key="tab.name"
            :value="tab.name"
          >
            <component
              :is="tab.component"
              :shop-context="shopContext"
              :product-id="item._id"
              :item="item"
              @refresh="fetch"
            />
          </v-tab-item>
        </v-tabs-items>

      </v-card-text>
      <v-card-actions>
        <v-btn
          icon
          @click="$emit('close')"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-spacer />
        <v-btn
          icon
          @click="deleteProductConfirm"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>

      </v-card-actions>

      <are-you-sure
        :opt="dialog"
        @clickedNo="dialog.show = false"
        @clickedYes="dialog.show = false; deleteProduct()"
      />

    </v-card>
  </v-bottom-sheet>
</template>

<script>
import Images from './images/Index'
import Properties from './properties/Index'
import Subscription from './subscription/Index'
import Goal from './goal/Index'
import Shipping from './shipping/Index'

export default {
  name:'product',
  props: {
    id: {
      type: String,
      default: ()=>null
    },
    show: {
      type: Boolean
    },
    shopContext: {
      type: String,
      default: ()=>null
    }
  },
  data() {
    return {
      dialog: {
        show: false,
        text: {}
      },
      forDelete: null,
      productTab: null,
      item: {},
      showCheck: null,
      tabs: [
        {name: 'Properties', icon: 'mdi-tune', component: Properties},
        {name: 'Goal', icon: 'mdi-thermometer', component: Goal},
        {name: 'Subscription', icon: 'mdi-repeat', component: Subscription},
        {name: 'Images', icon: 'mdi-image-multiple', component: Images},
        {name: 'Shipping', icon: 'mdi-truck', component: Shipping},
      ]
    };
  },
  watch:{
    id(){
      this.fetch()
    }
  },
  mounted(){
    this.fetch()
  },
  methods: {
    async fetch(){
      if (this.id) {
        this.item = await this.api.get(`/product/product/${this.id}`)
      }
    },
    toggleActive(){
      this.item.active = !this.item.active
      this.fieldChange('active', this.item.active)
    },
    async deleteProduct(){
      await this.api.delete(`/product/delete/${this.item._id}`)
      this.$emit('close')
    },
    deleteProductConfirm(){
      // this.dialog.text.title = `Delete ${this.item.name || this.item.number}?`
      this.dialog.text.subtitle = "You sure about dat?"
      this.dialog.show = true
    },
    async fieldChange(field, value){
      await this.api.put(`/product/update/${this.item._id}`, {[field]: value})
    },
  },
}
</script>