<template>
  <v-tabs
    v-model="shopTab"
    center-active
    show-arrows
    v-if="shops.length"
    background-color="transparent"
    @change="changeContext($event)"
    icons-and-text
  >
    <v-tabs-slider color="#424242"></v-tabs-slider>

    <v-tab
      v-for="shop in shops"
      :key="shop._id"
    >
      <span class="font-weight-bold">{{shop.uri}}</span>
      <v-icon
        :color="shop.livemode ? 'success' : 'secondary'"
      >
        mdi-circle-small
      </v-icon>
    </v-tab>
  </v-tabs>
</template>

<script>
export default {
  props: {
    loaded: {
      type: Number,
      default: ()=>null
    },
  },
  data(){
    return {
      shopTab: null
    }
  },
  computed:{
    cachedTab(){return this.$store.getters['base/shopTab']},
    shops(){return this.$store.getters['base/shops']}
  },
  async mounted(){
    this.shopTab = this.cachedTab
    this.fetch()
    this.changeContext(this.cachedTab || 0)
  },
  watch: {
    loaded() {
      this.fetch()
    }
  },
  methods:{
    changeContext(idx){
      this.$store.commit('base/shopTab', idx)
      this.$emit('context', this.shops[idx])
    },
    fetch(){
      this.$store.dispatch('base/getShops')
    }
  }
}
</script>