<template>
  <v-container
    fluid
    class="pa-0 ma-0"
  >
    <v-expansion-panel
      class="mt-4"
    >
      <v-expansion-panel-header
        class="d-flex flex-row justify-space-between"
        color="grey lighten-4"
        expand-icon="mdi-key-chain"
      >
        Stripe
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-container fluid>
          <v-card
            elevation="1"
          >
            <v-toolbar
              elevation="1"
              class="pr-2"
            >
              <v-icon left>
                mdi-store
              </v-icon>
              Accounts
              <v-spacer/>
              <v-btn
                small
                icon
                @click="fetch()"
                :loading="busy"
              >
                <v-icon
                >
                  mdi-sync
                </v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <v-chip-group
                v-model="chip"
                column
                @change="chipSelected"
              >
                <v-chip
                  v-for="account in accounts"
                  :key="account"
                  filter
                  outlined
                  close
                  @click:close="confirmDeleteAccount(account)"
                >
                  {{ account }}
                </v-chip>
              </v-chip-group>
            <v-divider />
              <v-text-field
                v-model="currentAccountName"
                outlined
                label="account name"
                @change="manualChange"
              ></v-text-field>
              <stripe-apikey
                :stripe="account"
                :newAccountName="currentAccountName"
                :disabled="!(chip + 1) || !currentAccountName"
                @refresh="fetch"
              />
            </v-card-text>
          </v-card>
        </v-container>

        <v-btn
          link
          icon
          small
          color="primary"
          target="_blank"
          :href="helpURL"
          style="text-decoration: none;"
        >
          <v-icon
            small
          >
            mdi-help
          </v-icon>
        </v-btn>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <are-you-sure 
      :opt="dialog"
      @clickedNo="deleteAccountName = null; dialog.show = false"
      @clickedYes="dialog.show = false; deleteAccount()"
    />
  </v-container>
</template>

<script>
import StripeApikey from './apikeys/Index'

export default {
  name: 'UserStripe',
  components: {
    StripeApikey
  },
  data(){
   return {
      busy: false,
      helpURL: 'https://dashboard.stripe.com/apikeys',
      currentAccountName: null,
      account: {},
      accounts: [],
      chip: null,
      dialog: {
        show: false,
        text: {
          title: 'Delete Account Keys...'
        }
      },
      deleteAccountName: null
    }
  },
  computed: {
    accountName() {
      return this.accounts[this.chip] 
    }
  },
  mounted () {
    this.fetch()
  },
  watch: {
    accountName() {
      this.fetch()
    }
  },
  methods:{
    async fetch(){
      this.busy = true
      const stripe = await this.api.get(`/stripe/user`)
      this.accounts = stripe.list
      if (this.accountName) {
        this.account = await this.api.get(`/stripe/user/${this.accountName}`)
      } else {
        this.account = {}
      }
      this.busy = false
    },
    chipSelected(){
      this.currentAccountName = this.accountName
    },
    manualChange(){
      if (this.currentAccountName && this.accounts.indexOf(this.currentAccountName) > -1) {
        console.log(this.currentAccountName, this.accounts)
        this.chip = this.accounts.indexOf(this.currentAccountName)
      } else {
        this.chip = null
      }
    },
    confirmDeleteAccount(account){
      this.dialog.text.title = `Delete ${account} keys...`
      this.deleteAccountName = account
      this.dialog.show = true
    },
    async deleteAccount(){
      this.busy = true
      console.log('delete account', this.deleteAccountName)
      await this.api.delete(`/stripe/user/${this.deleteAccountName}`)
      this.fetch()
    }
  }
}
</script>