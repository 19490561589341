<template>
  <v-card
    flat
    color="transparent"
  >
    <v-card-title>
      Flat Rate Shipping
    </v-card-title>
    <v-card-text>
      <v-text-field
        v-model="data.cost"
        outlined
        type="number"
        label="Flat Rate Shipping Cost"
        @change="update"
      />
      <v-text-field
        v-model="data.max_quantity"
        outlined
        type="number"
        label="Maximum Quantity"
        @change="update"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import { props } from './componentProps'

export default {
  name: 'FlatRate',
  props,
  data() {
    return {
      data: {}
    }
  },
  watch: {
    shipping() {
      this.data = this.shipping.flatrate || {}
    }
  },
  methods: {
    update(){
      const flatrate = this.data
      this.$emit('update', {flatrate})
    }
  },
}
</script>