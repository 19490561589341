<template>
  <v-expansion-panel>
    <v-expansion-panel-header class="pa-0">
      <v-col cols="12" class="pa-0 d-flex flex-row justify-space-between">
        <v-icon class="pr-2" large
          :color="!stripe.mode ? 'grey lighten-2' : 'primary'"
        >mdi-key-chain</v-icon>
        <p
          class="d-flex align-end font-weight-bold"
        >
        Stripe Details 
        </p>
        <v-spacer/>
        <v-icon
          :color="!stripe.mode ? 'grey lighten-2' : 'primary'"
        >{{stripe.mode==='test' ? 'mdi-test-tube' : 'mdi-cart'}}</v-icon>
        <v-spacer/>
      </v-col>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-container
        fluid
        class="pa-0 ma-0"
      >

        <stripe-apikey
          :shopID="shopId"
          :stripe="stripe"
          @refresh="fetch"
          :hint="description"
        />

        <copy-user-account-keys 
          @refresh="fetch"
          :shopID="shopId"
        />
        
        <v-col cols="12">
          <a target="_blank" :href="helpURL">{{ helpURL }}</a>
        </v-col>

        <currencies
          :shop-id="shopId"
          :stripe="stripe"
          @refresh="fetch"
        />

      </v-container>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import Currencies from './Currencies'
import StripeApikey from './apikeys/Index'
import CopyUserAccountKeys from './CopyUserAccountKeys.vue'
import { props } from '../helper'

export default {
  name: 'ShopStripe',
  components: {
    Currencies,
    StripeApikey,
    CopyUserAccountKeys
  },
  props,
  data(){
    return {
      description: 'This control sends your Stripe keys once to your account, overwriting any keys previously sent. This key is not accessable again from this client.',
      helpURL: 'https://dashboard.stripe.com/apikeys',
      stripe: {}
    }
  },
  watch:{shopId(){this.fetch()}},
  methods:{
    async fetch(){
      this.stripe = await this.api.get(`/stripe/status/${this.shopId}`)
    }
  }
}
</script>