<template>
  <v-footer
    app
    padless
    fixed
    flat
    color="transparent"
  >
    <v-row
      justify="center"
      no-gutters
    >
      <v-spacer />
      <v-col>
      <v-tabs
        v-model="footerNavIndex"
        background-color="transparent"
      >
        <v-tab
          v-for="(butt, i) in footerButtons"
          :key="i"
        >
          <v-badge
            color='red'
            overlap
            :content="orderTally"
            :value="orderTally"
            offset-y="20"
            offset-x="10"
          >
            <v-icon
              large
              :disabled="footerNavIndex != i"
            >
              {{footerIcons[i]}}
            </v-icon>
          </v-badge>
        </v-tab>

      </v-tabs>
      </v-col>
      <v-spacer />
    </v-row>
  </v-footer>
</template>

<script>
export default {
  data() {
    return {
      footerButtons: [
        // include an activeIcon if you like
        {name: 'settings', icon: 'mdi-store'},
        {name: 'products', icon: 'mdi-shopping'},
        {name: 'contacts', icon: 'mdi-mailbox', activeIcon: 'mdi-mailbox-open'},
        {name: 'orders', icon: 'mdi-truck'},
      ],
      footerNavIndex: 0
    }
  },
  watch: {
    footerNavIndex(idx) {
      this.$emit('change', this.footerButtons[idx])
    }
  },
  computed:{
    footerIcons(){
      return this.footerButtons.map((butt, i)=>{
        const active = this.footerNavIndex == i
        if (active) {
          return butt.activeIcon || butt.icon
        }
        return butt.icon
      }) || []
    },
    orderTally(){return this.$store.state.base.orders.length}
  },

}
</script>

<style scoped>
.v-tabs-slider-wrapper {
  left: auto !important;
  right: 0;
}
</style>