<template>
  <v-select
    v-model="editColors"
    :items="availableColors"
    label="Colors"
    multiple
    chips
    hint="Colors available for purchase"
    persistent-hint
    @change="colorsChanged($event)"
  >
    <template v-slot:prepend-item>
      <v-list-item
        ripple
        @click="toggle"
      >
        <v-list-item-action>
          <v-icon :color="colors.length > 0 ? 'indigo darken-4' : ''">
            {{ icon }}
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            Select All
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2"></v-divider>
    </template>
  </v-select>

</template>

<script>
import {productColors} from '@/helper'

export default {
  props: ['colors'],
  data() {
    return {
      availableColors: productColors,
    };
  },
  computed: {
    editColors: {
      get(){return this.colors},
      set(){}
    },
    isAllColors(){return this.availableColors.length == this.colors.length},
    isSomeColors(){return this.colors.length > 0 && !this.isAllColors},
    icon () {
      if (this.isAllColors) return 'mdi-close-box'
      if (this.isSomeColors) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  },
  methods: {
    colorsChanged(v) {
      if (Array.isArray(v)) {
        this.$emit('change', v)
      }
    },
    toggle () {
      this.$nextTick(() => {
        if (this.isAllColors) {
          this.$emit('change', [])
        } else {
          this.$emit('change', this.availableColors.slice()||[])
        }
      })
    },
  },
}
</script>