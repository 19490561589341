import faker from 'faker'

export function singleize(plural){return plural.slice(0,-1)}
export function capitalize(word){return word.charAt(0).toUpperCase() + word.slice(1)}

export const productColors = ['white', 'black', 'red', 'blue', 'yellow']
export const productSizes = ['sm', 'md', 'lg', 'xl', '2xl', '3xl']
export const statesObj = {
  "AL": "Alabama",
  "AK": "Alaska",
  "AS": "American Samoa",
  "AZ": "Arizona",
  "AR": "Arkansas",
  "CA": "California",
  "CO": "Colorado",
  "CT": "Connecticut",
  "DE": "Delaware",
  "DC": "District Of Columbia",
  "FM": "Federated States Of Micronesia",
  "FL": "Florida",
  "GA": "Georgia",
  "GU": "Guam",
  "HI": "Hawaii",
  "ID": "Idaho",
  "IL": "Illinois",
  "IN": "Indiana",
  "IA": "Iowa",
  "KS": "Kansas",
  "KY": "Kentucky",
  "LA": "Louisiana",
  "ME": "Maine",
  "MH": "Marshall Islands",
  "MD": "Maryland",
  "MA": "Massachusetts",
  "MI": "Michigan",
  "MN": "Minnesota",
  "MS": "Mississippi",
  "MO": "Missouri",
  "MT": "Montana",
  "NE": "Nebraska",
  "NV": "Nevada",
  "NH": "New Hampshire",
  "NJ": "New Jersey",
  "NM": "New Mexico",
  "NY": "New York",
  "NC": "North Carolina",
  "ND": "North Dakota",
  "MP": "Northern Mariana Islands",
  "OH": "Ohio",
  "OK": "Oklahoma",
  "OR": "Oregon",
  "PW": "Palau",
  "PA": "Pennsylvania",
  "PR": "Puerto Rico",
  "RI": "Rhode Island",
  "SC": "South Carolina",
  "SD": "South Dakota",
  "TN": "Tennessee",
  "TX": "Texas",
  "UT": "Utah",
  "VT": "Vermont",
  "VI": "Virgin Islands",
  "VA": "Virginia",
  "WA": "Washington",
  "WV": "West Virginia",
  "WI": "Wisconsin",
  "WY": "Wyoming"
}
export const statesList = Object.keys(statesObj).map(k=>{
  return {abbr: k, name: statesObj[k]}
})

export const demo = {
  hide: false,
  email: faker.internet.email(),
  username: faker.fake('{{name.lastName}}'),
  password: 'asdf',
  shop: {
    owners_name: faker.fake('{{name.firstName}} {{name.lastName}}'),
    organization_name: faker.company.companyName(),
    email: faker.internet.email(),
    phone: faker.phone.phoneNumber(),
    address: faker.fake('{{address.streetAddress}} {{address.streetName}} {{address.streetSuffix}}\n{{address.city}}, {{address.state}} {{address.zipCode}}'),
    description: faker.company.catchPhrase(),
  },
  product: {
    number: faker.finance.account(),
    name: faker.commerce.productName(),
    description: faker.commerce.productDescription()
  }
}

export const getToken = ()=>{
  let vuex = window.localStorage.getItem('vuex')
  let state = JSON.parse(vuex)
  return state && state.token
}