<template>
  <v-card
    elevation="0"
    class="pa-1 ma-1"
    :color="$vuetify.theme.dark ? null : 'grey lighten-3'"
  >
    <v-card-title>
      {{ message.name }}
    </v-card-title>
    <v-card-subtitle>
      {{ message.email }}
    </v-card-subtitle>
    <v-card-text>
      {{ message.body }}
    </v-card-text>
    <v-card
      v-for="(reply, i) in message.replies"
      :key="i"
      class="pa-1 ma-1"
    >
      {{ reply.reply_message }}
    </v-card>
    <v-card-actions
      class="d-flex flex-row flex-wrap justify-end"
    >
      <v-textarea
        v-model="message.reply_message"
        outlined
        label="Reply"
        class="mx-2"
        style="flex-basis: 100%;"
        persistent-placeholder
      />
      <v-btn
        icon
        color="success"
        :disabled="!message.reply_message"
        @click="reply(message)"
      >
        <v-icon>mdi-send</v-icon>
      </v-btn>
      <v-btn
        icon
        color="primary"
        @click="$emit('archive', message)"
      >
        <v-icon>mdi-archive</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
  
</template>

<script>
export default {
  props: {
    message: {
      type: Object,
      default: ()=>{return {}}
    },
  },
}
</script>