<template>
  <v-expansion-panel>
    <expansion-panel-header
      title="Crypto Details"
      icon="mdi-currency-btc"
    />
    <v-expansion-panel-content>
      <v-container
        fluid
        class="pa-0 ma-0 d-flex flex-column"
      >
        <coin
          v-for="coin in coins"
          :key="coin.name"
          :coin="coin"
          @update="updateCrypto"
        />
      </v-container>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import ExpansionPanelHeader from '../ExpansionPanelHeader'
import Coin from  './Coin'
import { props } from '../helper'

export default {
  props,
  components: {
    ExpansionPanelHeader,
    Coin,
  },
  data() {
    return {
      coins: []
    }
  },
  mounted () {
    this.fetch()
  },
  watch: {
    shopId() {
      this.fetch()
    }
  },
  methods:{
    async fetch(){
      if (this.shopId) {
        this.coins = await this.api.get(`/crypto/coins/${this.shopId}`) || []
      }
    },
    async updateCrypto(v){
      // eslint-disable-next-line no-unused-vars
      const {logo, ...coin} = v
      await this.api.post('/shop/module', {
        name: 'crypto',
        shopID: this.shop._id,
        data: {...this.crypto, [coin.name]: coin}
      })
      this.$emit('refresh')
    }
  }
}
</script>

<style>

</style>