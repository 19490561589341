<template>
  <v-container fluid class="d-flex flex-column justify-center">

    <v-container>
      <v-row>
        <v-col
          v-for="(mode, n) in stripe.modes"
          :key="n"
        >
          <v-card
            flat
            outlined
            :color="mode.active ? 'primary' : 'grey lighten-1'"
            class="d-flex align-center"
            dark
            height="200"
            min-width="150"
            max-width="150"
            @click="toggleModeConfirm(n)"
            :disabled="!mode.ready"
          >
            <v-scroll-y-transition>
              <div
                class="display-1 flex-grow-1 text-center"
              >
                {{mode.name}}
                <v-chip 
                  :outlined="!status"
                  :disabled="!status"
                  class="ma-2"
                  color="green"
                  v-for="(status, type) in mode.status"
                  :key="type"
                >
                  <v-icon left>mdi-key</v-icon>
                  {{type}}
                </v-chip>
              </div>
            </v-scroll-y-transition>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <stripe-add-key
      @add-key="sendStripeKey"
      :hint="hint"
    />

    <are-you-sure
      :opt="dialog"
      @clickedNo="dialog.show=false"
      @clickedYes="dialog.show=false; toggleMode(modeCandidate)"
    />
  </v-container>
</template>

<script>
import StripeAddKey from './StripeAddKey'

export default {
  props:['stripe', 'shopID', 'hint'],
  components: {StripeAddKey},
  data(){
    return {
      modeCandidate: null,
      activeMode: null,
      busy: false,
      dialog: {
        show: false,
        text: {}
      },
    }
  },
  computed:{
    modeIndex(){return this.stripe.modes.findIndex(m=>m.name===this.stripe.mode)}
  },
  watch:{shopID(){this.refreshControl()}},
  mounted(){this.refreshControl()},
  methods:{
    refreshControl(){
      this.activeMode = this.modeIndex
    },
    toggleModeConfirm(v){
      if (v != this.activeMode) {
        this.modeCandidate = v
        this.dialog.text.title = v==1 ? 'Going live...' : 'Returning to test mode...'
        this.dialog.text.subTitle = null
        this.dialog.show = true
      }
    },
    async toggleMode(v){
      this.activeMode = v
      await this.api.post('/shop/module',{
        name: 'stripe',
        shopID: this.shopID,
        data: {mode: this.stripe.modes[v].name}
      })
      this.$emit('refresh')
    },
    async sendStripeKey(token){
      this.busy = true
      await this.api.post('/stripe/token', {
        token: token,
        shopID: this.shopID,
      })
      this.busy = false
      this.$emit('refresh')
    }
  }
}
</script>