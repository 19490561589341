<template>
  <v-card
    flat
    max-width="800px"
    class="mx-auto"
    align="middle"
    color="transparent"
  >
    <v-container fluid>

      <v-card
        flat
        max-width="600"
        color="transparent"
      >
        <v-expansion-panels
          v-model="panels"
          flat
          popout
        >
          <component
            v-for="(component, i) in sections"
            :key="i"
            :is="component"
            :shop-id="shop._id"
            :shop="shop"
            @refresh="fetch"
            @delete-shop="confirmDelete"
            style="background-color: rgba(0,0,0,0);"
            class="mb-2"
            v-on="$listeners"
          />
        </v-expansion-panels>
      </v-card>

      <are-you-sure
        :opt="dialog"
        @clickedNo="dialog.show = false"
        @clickedYes="
          dialog.show = false;
          deleteShop();
        "
      />
    </v-container>
  </v-card>
</template>

<script>
import ShopProperties from './Properties';
import Users from "./users/Index";
import Stripe from "./stripe/Index";
import Crypto from "./crypto/Index";
import Mailchimp from "./Mailchimp";
import Youtube from "./Youtube";
import Discord from "./Discord"
import Shippo from './Shippo'

export default {
  props: ['shop_context'],
  data: () => {
    return {
      idForDelete: null,
      dialog: {
        show: false,
        text: {},
      },
      initialUri: null,
      panels: [],
      shop: {},
      sections: [
        ShopProperties,
        Users,
        Stripe,
        Crypto,
        Mailchimp,
        Youtube,
        Discord,
        Shippo
      ]
    };
  },
  mounted() {
    this.initialUri = this.shop.uri;
    this.fetch();
  },
  computed: {
    isUriOk() {
      return (
        (this.shop.uri && this.shop.uri.length == 0) ||
        this.shop.uri == this.initialUri ||
        this.$store.state.base.isShopURIUnique ||
        false
      );
    },
    isDemo() {
      return this.$store.state.base.isDemo;
    },
  },
  watch:{
    shop_context(){
      this.fetch()
    }
  },
  methods: {
    openClosed(){
      if(this.shop.livemode){
        return '$openSign'
      } else {
        return '$closedSign'
      }
    },
    activeColor(){
      if (this.shop.livemode){
        return 'primary'
      } else {
        return 'grey'
      }
    },
    async fetch(){
      if (this.shop_context && this.shop_context._id) {
        this.shop = await this.api.get(`/shop/edit_shop/${this.shop_context._id}`)
      }
    },
    confirmDelete() {
      this.idForDelete = this.shop._id;
      this.dialog.text.title = `Delete ${this.shop.organization_name}`;
      this.dialog.show = true;
    },
    deleteShop() {
      this.$store.dispatch("deleteShop", this.idForDelete);
    },
  },
};
</script>