<template>
  <v-expansion-panel-header
    class="pa-0"
  >
    <v-col cols="12" class="pa-0 d-flex flex-row">
      <v-badge
        color='primary'
        overlap
        left
        :content="badgeContent"
        :value="!!badgeContent"
      >
        <v-icon
          class="mr-2"
          :size="iconSize"
          :color="isGood ? 'primary' : ''"
        >
          {{ icon }}
        </v-icon>
      </v-badge>
      <p
        class="d-flex align-end font-weight-bold"
      >
        {{ title }}
      </p>
      <v-spacer />
        <v-icon
          :size=gageSize
          class=""
          style="fill: currentColor;"
          :color=gageColor
        >
          {{ gage }}
        </v-icon>
      <v-spacer />
    </v-col>
  </v-expansion-panel-header>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ()=>null
    },
    icon: {
      type: String,
      default: ()=>null
    },
    iconSize: {
      type: Number,
      default: 40
    },
    badgeContent: {
      type: Number,
      default: ()=>null
    },
    isGood: {
      type: Boolean
    },
    gage: {
      type: String,
      default: ()=>null
    },
    gageSize: {
      type: Number,
      default: 20
    },
    gageColor: {
      type: String,
      default: "secondary"
    }
  }
}
</script>