<template>
  <v-container>
    <v-snackbar
      v-model="sb.show"
      :timeout="sb.timeout"
    >
      {{ sb.text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="primary"
          text
          v-bind="attrs"
          @click="sb.show = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
  export default {
    computed:{
      sb(){return this.$store.getters['base/snackbar']|| {}}
    }
  }
</script>