const authHeader = (token) => {
  return {headers: {Authorization: `bearer ${token}`}}
}

const actions = {

  saveColor({commit}, opt){commit('saveColors', opt)},

  initializeStateStuff({commit}, routeMeta){
    commit('initializeStateStuff', routeMeta)
  },

  editProductDialog({commit}, opt){commit('editProductDialog', opt)},
  
  deleteProductImage({commit}, opt){
    this._vm.api.delete('product/image', {...{params: opt}, ...authHeader(this.state.token)}).then(()=>{
      commit('assignState_pullImg', opt)
    }).catch(err=>{
      console.log('Error while deleting product image', err)
    })
  },
  
  SOCKET_UNAUTHORIZED(){
    console.log('Socket returned UNAUTHORIZED')
  },

  async SOCKET_CREATE_PRODUCT({commit}, opt){
    await commit('M_CREATE_PRODUCT', opt)  
    if (opt.editAfter) {
      await commit('setProductID', opt.data._id)
      await commit('editProductDialog', true)
    }
  },

  async getShops({commit}){
    const shops = await this._vm.api.get('/shop/shops')
    commit('shops', shops)
  },

  async deleteShop({dispatch}, shopID){
    console.log({shopID})
    await this._vm.api.delete(`/shop/shop/${shopID}`)
    dispatch('getShops')
  },

  async newStuff_shop({dispatch}){
    await this._vm.api.post('/shop/create')
    dispatch('getShops')
  },

  async newStuff_product(shopID){
    await this._vm.api.post(`/product/create/${shopID}`)
  }


}

export default actions