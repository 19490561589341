<template>
  <v-container fluid class="d-flex flex-wrap justify-start px-0">
    <v-col cols="6" class="pa-0 ma-0">
      <v-btn
        elevation="1"
        @click="newPrice"
        color="primary"
        :disabled="disabled"
      >
        <v-icon left>mdi-plus</v-icon>New Price
      </v-btn>
    </v-col>
    <v-col cols="6" class="pa-0 ma-0">
      <v-checkbox
        v-model="sub.allow_custom_price"
        :ripple="false"
        label="Allow Custom"
        :disabled="disabled"
        @change="save('allow_custom_price', $event)"
      />
    </v-col>

    <price
      v-for="(price, i) in prices"
      :key="i"
      :price="price"
      :disabled="disabled"
      :idx="i"
      @toggle="toggle"
      @change="updatePrice"
      @delete="confirmDeletePrice"
    />

    <price
      :disabled="disabled"
      :custom="true"
      v-if="sub.allow_custom_price"
    />

    <are-you-sure
      :opt="dialog"
      @clickedNo="deleteIdx = null; dialog.show = false"
      @clickedYes="dialog.show = false; deletePrice()"
    />

  </v-container>
</template>

<script>
import Price from './Price'

export default {
  props: ['disabled', 'item'],
  components: { Price },
  data: ()=>{
    return {
      dialog: {
        text: {},
        show: false
      },
      deleteIdx: null
    }
  },
  computed: {
    sub(){return this.item.subscription || {}},
    prices(){return this.sub.prices || []}
  },
  methods: {
    toggle(idx){
      let price = this.prices[idx]
      price.enabled = !price.enabled
      this.save(this.prices)
    },
    updatePrice(opt){
      console.log(opt)
      let { idx, amount } = opt
      let price = this.prices[idx]
      price.amount = amount
      this.save('prices', this.prices)
    },
    async save(field, value){
      await this.api.post('/product/module', {
        name: 'subscription',
        productID: this.item._id,
        data: {[field]: value}
      })
      this.$emit('refresh')
    },
    newPrice(){
      this.save('prices', [...this.prices, ...[{amount: null, enabled: true}]])
    },
    confirmDeletePrice(idx){
      this.deleteIdx = idx
      this.dialog.show = true
    },
    deletePrice(){
      if (this.deleteIdx || this.deleteIdx == 0) {
        this.save('prices', this.prices.filter((r, i)=>{return i!=this.deleteIdx}))
        this.deleteIdx = null
      }
    }
  }
}
</script>