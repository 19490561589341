<template>
  <v-speed-dial>
    <template v-slot:activator>
      <v-badge
        overlap
        icon="mdi-pencil"
        left
        offset-x="20"
        :color="user.roles.includes('editor')? 'green' : 'grey lighten-1  '"
      >
        <v-chip
          close
          class="mx-1 mb-3"
          @click:close="$emit('remove', user)"
        >
          <v-avatar>
            <v-icon>mdi-account</v-icon>
          </v-avatar>
          {{ user.username }}
        </v-chip>
      </v-badge>
    </template>

    <v-btn
      v-for="role in roles"
      :key="role.name"
      fab
      dark
      small
      :color="user.roles.includes(role.name) ? 'green' : 'grey lighten-1'"
      @click="toggleRole(role.name)"
    >
      <v-icon>{{ role.icon }}</v-icon>
    </v-btn>
  </v-speed-dial>
</template>

<script>
export default {
  props: {
    shopId: {
      type: String,
      default: null
    },
    user: {
      type: Object,
      default: ()=>{return {}}
    },
  },
  data() {
    return {
      roles: [
        {name: 'editor', icon: 'mdi-pencil', color: 'green'}
      ]
    }
  },
  methods: {
    async toggleRole(roleName) {
      await this.api.put(`/shop/roles/${this.shopId}/${roleName}/${this.user.username}`)
      this.$emit('refresh')
    }
  },
}
</script>