const defaultState = {
  darkmode: false,
  shops: [],
  shopTab: null,
  
  // refactored above 
  isMerchant: true,
  isDemo: false,

  products: {},
  shopID: null,
  editShop: false,
  isShopURIUnique: null,
  
  productID: null,
  editProductDialog: false,
  
  stripe_currencies: [],
  stripe_intervals: [],
  orders: [],

  file_uploading: null,
  stripe_session_id: null,
  routeMeta: {},
  snackbar: {
    show: false,
    text: 'My timeout is set to 2000.',
    timeout: 2000,
  },
  purgeClientData: false
}

export default defaultState
