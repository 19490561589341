<template>
  <v-expansion-panel>
    <expansion-panel-header
      title="YouTube"
      icon="mdi-youtube"
    />
    <v-expansion-panel-content>
      <v-text-field
        v-model="youtube.channel_id"
        label="Channel ID"
        outlined
        dense
        @change="fieldChange"
      />
      <v-text-field
        v-model="youtube.api_key"
        label="API Key"
        outlined
        dense
        @change="fieldChange"
      />
      <v-text-field
        v-model="youtube.featured_playlist"
        label="Featured Playlist"
        hint="The playlist entered here will be the default playlist displayed in your merchbase market shop."
        outlined
        dense
        @change="fieldChange"
      />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import ExpansionPanelHeader from './ExpansionPanelHeader'

export default {
  components: {
    ExpansionPanelHeader,
  },
  props: {
    shop: {
      type: Object,
      default: ()=>{return {}}
    },
  },
  computed:{
    youtube(){return this.shop.youtube || {}},
    isGood(){
      return !!this.youtube
        && !!this.youtube.channel_id
        && !!this.youtube.api_key
    }
  },
  methods:{
    async fieldChange(){
      await this.api.post('/shop/module', {
        name: 'youtube',
        shopID: this.shop._id,
        data: this.youtube
      })
      this.$emit('refresh')
    }
  }
}
</script>