<template>
  <div v-cloak @drop.prevent="addDropFiles" @dragover.prevent>
    <v-file-input
      multiple
      id='fileUploadComponent'
      chips
      v-model="files"
      ref="image"
      @click.stop
      show-size
      prepend-icon="mdi-image-multiple"
      :label="label || 'Click or Drop (1Mb max, png/jpeg only)'"
      accept="image/png, image/jpeg"
      :disabled="busy"
      :loading="busy"
    /> 
  </div>
</template>

<script>
export default {
  name: 'FileUpload',
  props: {
    shopId: {
      type: String,
      default: ()=>null
    },
    productId: {
      type: String,
      default: ()=>null
    },
    label: {
      type: String,
      default: ()=>null
    },
    purpose: {
      type: String,
      default: ()=>null
    },
  },
  data(){
    return {
      files: null,
      busy: false,
    }
  },
  watch: {
    files(v){
      if (v) {
        this.processFiles()
      }
    },
    loading(v){
      if (v===false) {
        this.files = null
      }
    } 
  },
  methods: {
    addDropFiles(e) {
      this.files = e.dataTransfer.files; 
    },

    processFiles(){
      if (this.files.length && !this.busy) {
        this.busy = true
          this.files.forEach(async file => {
            if (file.size > 1024000) {
              this.$store.commit('SOCKET_SNACKBAR', {
                message: `${file.name} is to large. Maximum file size is 1MB`
              })
            } else {
              await this.uploadFile(file)
            }
          })
          this.$refs.image.reset()
          this.busy = false
      }
    },

    async uploadFile(imageObj) {
      const formData = new FormData()
      formData.append('image', imageObj)
      formData.append('productID', this.productId)
      if (this.purpose) formData.append('purpose', this.purpose)
      if (this.shopId) formData.append('shopId', this.shopId)
      this.api.post('/file-upload/single', formData, {'Content-Type': 'multipart/form-data'}).then(()=>{
        this.$emit('refresh')
      }).catch(err=>{
        console.error(err)
      })
    }
  }

}
</script>