<template>
  <v-container>

    <v-switch
      inset
      v-model="sub.enabled"
      @change="fieldChange('enabled', $event)"
      label="Subscriptions"
    />

    <prices
      :disabled="!sub.enabled"
      :item="item"
      v-on="$listeners"
    />

    <intervals
      :intervals="subIntervals"
      :oneTime="oneTime"
      :disabled="!sub.enabled"
      :shop-context="shopContext"
      @change="changeIntervals"
      v-on="$listeners"
    />

    <v-textarea
      class="pa-4"
      outlined
      label="Thank You Message"
      hint="Message displayed after supscription checkout."
      persistent-hint
      v-model="thankYouMessage"
      :disabled="!sub.enabled"
      @change="fieldChange('thank_you_message', $event)"
    />

  </v-container>
</template>

<script>
import Prices from './Prices'
import Intervals from './Intervals.vue'

export default {
  name: 'ProductSubscription',
  props: {
    item: {
      type: Object,
      default: ()=>{return {}}
    },
    shopContext: {
      type: String,
      default: ()=>null
    }
  },
  components: { Prices, Intervals },
  data(){
    return {
    }
  },
  computed: {
    subIntervals(){return (this.item.subscription||{}).intervals || []},
    sub(){return this.item.subscription || {}},
    thankYouMessage:{
      get(){return this.sub.thank_you_message || null},
      set(){}
    },
    oneTime(){
      return {
        allowOneTimePayment: this.sub.allow_one_time_payment,
        allowOneTimePaymentCustomText: this.sub.allow_one_time_payment_custom_text
      }
    }
  },
  methods:{
    changeIntervals(v){
      console.log('changing intervals', v)
      Object.keys(v).forEach(key => {
        this.fieldChange(key, v[key])
      });
    },
    async fieldChange(f, v) {
      await this.api.post('/product/module', {
        name: 'subscription',
        productID: this.item._id,
        data: {[f]: v}
      })
      this.$emit('refresh')
    }
  }
}
</script>