<template>
  <v-row dense>
    <v-col cols="10">
      <v-form ref="form" v-model="valid">
        <v-text-field
          v-model="token"
          label="Enter Stripe Api Key"
          :rules="[rules.stripePrefix]"
          :disabled="locked"
          :hint="hint"
          persistent-hint
          prepend-icon="mdi-fingerprint"
        />
      </v-form>
    </v-col>
    <v-col cols="2">
      <v-btn
        raised
        elevation="0"
        icon
        rounded
        large
        class="mt-3"
        color="primary"
        :disabled="!valid"
        @click="sendKey"
      >
        <v-icon>mdi-send-lock</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    hint: {
      type: String,
      default: null
    },
  },
  data(){
    return {
      valid: false,
      locked: false,
      token: '',
      cleanupDelay: 3000,
      rules: {
        stripePrefix: value => RegExp('^[sp]k_(live|test)_..........').test(value) || 'Not a valid Stripe key.',
      },
    }
  },
  methods: {
    sendKey(){
      this.$emit('add-key', this.token)
      this.$refs.form.reset()
    }
  }
}
</script>