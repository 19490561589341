<template>
  <v-card
    flat
    class="ma-0 pa-0"
  >
    <v-radio-group v-model="shipping.kind"
      @change="update({kind: shipping.kind})"
    >
      <v-card
        rounded
        v-for="(kind, i) in kinds"
        :key="i"
        class="ma-2 pa-0 d-flex flex-row align-start transition-swing rounded-xl"
        :flat="shipping.kind == kind.name ? false : true"
      >
        <v-radio
          :value="kind.name"
          class="pa-4"
        />
        <component
          :is="kind.component"
          :shipping="item.shipping"
          @update="update"
        />
      </v-card>
    </v-radio-group>

  </v-card>
</template>

<script>
import None from './None'
import Free from './Free'
import Shippo from './Shippo'
import FlatRate from './FlatRate'
import { props } from '../componentProps'

export default {
  name: 'ProductShipping',
  props,
  data() {
    return {
      kinds: [
        {name: 'none', component: None},
        {name: 'free', component: Free},
        {name: 'flatrate', component: FlatRate},
        {name: 'shippo', component: Shippo}
      ],
      enabled: null
    }
  },
  methods: {
    async update(data) {
      const shipping = {...this.item.shipping, ...data}
      await this.api.post('/product/module', {
        name: 'shipping',
        productID: this.item._id,
        data: shipping
      })
      this.$emit('refresh')
    }
  },
  computed: {
    shipping(){return this.item.shipping || {}},
  }
}
</script>