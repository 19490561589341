<template>
  <v-card
    flat
    color="transparent"
  >
    <v-card-title>
      No Shipping
    </v-card-title>
    <v-card-subtitle>
      This product does not require the capture of a shipping address at checkout
    </v-card-subtitle>
  </v-card>
</template>

<script>
import { props } from './componentProps'

export default {
  name: 'None',
  props,
}
</script>