<template>
  <v-container>
    <select-list
      label="Intervals"
      :items="available_intervals"
      :listModel="intervals"
      :disabled="disabled"
      @changed="saveIntervals"
    />
    <div
      class="d-flex flex-row"
    >
      <v-checkbox
        v-model="allowOneTimePayment"
        :label="allowOneTimePayment ? null : 'Allow one-time payment'"
        :disabled="disabled"
        @change="$emit('change', {allow_one_time_payment: allowOneTimePayment})"
      />

      <v-text-field
        v-if="allowOneTimePayment"
        v-model="allowOneTimePaymentCustomText"
        outlined
        label="Custom Text"
        hint="Leave blank for: 'Only make this payment once'"
        persistent-hint
        @blur="$emit('change', {allow_one_time_payment_custom_text: allowOneTimePaymentCustomText})"
      />
    </div>
  </v-container>
</template>

<script>
import SelectList from "./SelectList.vue"

export default {
  components: { SelectList },
  props: {
    intervals: {
      type: Array,
      default: ()=>[]
    },
    oneTime: {
      type: Object,
      default: ()=>{return {}}
    },
    disabled: {
      type: Boolean
    },
    shopContext: {
      type: String,
      default: ()=>null
    }
  },
  data() {
    return {
      available_intervals: [],
      allowOneTimePayment: null,
      allowOneTimePaymentCustomText: null
    }
  },
  watch: {
    oneTime: {
      handler(v){
        this.allowOneTimePayment = v.allowOneTimePayment
        this.allowOneTimePaymentCustomText = v.allowOneTimePaymentCustomText
      },
      deep: true
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    async fetch(){
      const stripe = await this.api.get(`/stripe/status/${this.shopContext}`)
      if (stripe.default && stripe.default.intervals) {
        this.available_intervals = Object.keys(stripe.default.intervals)
      }
    },
    saveIntervals(v){
      console.log('saveIntervals', v)
      this.$emit('change', {intervals: v})
    }
  },
}
</script>

