<template>
  <v-expansion-panel>
    <expansion-panel-header
      title="Shippo"
      :isGood="isGood"
      icon="$shippo"
      :iconSize=40
      style="fill: currentColor"
    />
    <v-expansion-panel-content>
    <v-text-field
      label="API Key"
      outlined
      dense
      v-model="data.api_key"
      @change="update"
      :type="show ? 'text' : 'password'"
      :append-icon="show ? 'mdi-eye-off' : 'mdi-eye'"
      @click:append="show = !show"
    />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import ExpansionPanelHeader from './ExpansionPanelHeader.vue'
import  { props } from './helper'

export default {
  components: { ExpansionPanelHeader },
  props,
  data() {
    return {
      show: false,
      data: {},
    }
  },
  watch: {
    shopId() {
      this.data = this.shop.shippo || {}
    }
  },
  computed: {
    isGood() {
      return !!this.data.api_key 
    }
  },
  methods: {
    async update() {
      await this.api.post('/shop/module', {
        name: 'shippo',
        shopID: this.shop._id,
        data: this.data
      })
      this.$emit('refresh')
    },
  }
}
</script>