<template>
  <v-card
    flat
    color="transparent"
  >
    <v-btn-toggle
      v-model="status"
      mandatory
      rounded
      borderless
    >
      <v-btn
        value="inbox"
      >
        <span class="hidden-sm-and-down">inbox</span>
        <v-icon>mdi-inbox</v-icon>
      </v-btn>
      <v-btn
        value="archive"
      >
        <span class="hidden-sm-and-down">archived</span>
        <v-icon>mdi-archive</v-icon>
      </v-btn>
    </v-btn-toggle>

    <message
      v-for="message in contacts"
      :key="message._id"
      :message="message"
      @archive="archiveMessage"
    />

    <empty-message
      :status="status"
      :disabled="!!contacts.length"
    />
    
  </v-card>
</template>

<script>
import Message from './Message'
import EmptyMessage from './EmptyMessage'

export default {
  components: {
    Message,
    EmptyMessage
  },
  props: {
    shopContext: {
      type: String,
      default: ()=>null
    },
  },
  data() {
    return {
      contacts: [],
      status: 'inbox'
    }
  },
  watch: {
    shopContext() {
      this.fetch()     
    },
    status() {
      this.fetch()     
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    async fetch() {
      if (this.shopContext) {
        this.contacts = await this.api.get(`/contact/${this.shopContext}/${this.status}`)
      }
    },
    async reply(message) {
      await this.api.post('/contact/reply', message)
      this.fetch();
    },
    async archiveMessage(message){
      const {_id, status} = message
      const restore = status == 'archive'
      await this.api.post('/contact/archive', {id: _id, restore})
      this.fetch()
    }
  },
}
</script>