<template>
  <v-bottom-sheet
    v-model="opt.show"
    :persistent="opt.persistent"
  >
    <v-card class="pa-3 d-flex">
        <v-col cols="6" align-self="center">
            <v-card-title class="font-weight-bold error--text">
                {{opt.text.title}}
            </v-card-title>
            <v-card-subtitle class="error--text">
                {{opt.text.subTitle || 'Are you sure?'}}
            </v-card-subtitle>
        </v-col>
        <v-col cols="6" align-self="center">
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    outlined
                    @click="takeAction(false)"
                >
                Nope!
                </v-btn>
                <v-btn
                    elevation="0"
                    color="error"
                    @click="takeAction(true)"
                >
                Yes I'm sure
                </v-btn>
            </v-card-actions>
        </v-col>
    </v-card>
  </v-bottom-sheet>

</template>

<script>
export default {
  name: 'areYouSure',
  props: {
    opt: {
      type: Object,
      default: ()=>{return {
        text: {},
        show: false
      }}
    },
  },
  methods: {
    takeAction(yes) {
      this.$emit(yes ? 'clickedYes' : 'clickedNo')
    }
  }
}
</script>