<template>
  <v-app>
    <v-app-bar
      app
      color="transparent"
      hide-on-scroll
      elevation="0"
    >
      <mb-logo />
  
      <v-spacer></v-spacer>
  
      <mb-account-menu 
        :stripe-options="stripeOptions"
        :user-slot="userSlot"
      />
    </v-app-bar>

    <base-index
      v-if="auth.set"
      :auth="auth"
    />
    
    <snackbar />
  </v-app>
</template>

<script>
import MbLogo from './Logo'
import BaseIndex from './base/Index'
import Snackbar from '@/shared/Snackbar'

export default {
  name: 'App',
  components: {
    MbLogo,
    BaseIndex,
    Snackbar
  },
  mounted(){
    console.log({
      shopURI: this.shopURI,
      context: this.context,
      version: process.env.VUE_APP_VERSION
    })
    this.$vuetify.theme.dark = this.$store.getters['base/darkmode']
  },
  data() {
    return {
      stripeOptions: {
        hide_all: true,
        // hide_payment_methods: true,
        // hide_subscriptions: true
      }, 
      userSlot: {
        icon: 'mdi-account-cog',
        componentName: 'user-stuff'
      }
    }
  },
};
</script>

<style>
#v-app {
  text-align: center;
}
</style>