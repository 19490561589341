<template>
  <v-row dense>
    <v-select
      v-model="myCurrencies"
      :items="stripe.default.currencies"
      label="Currencies Available"
      multiple
      chips
      hint="Currencies available for use in shop"
      persistent-hint
      @change="changedMyCurrencies"
      prepend-icon="mdi-currency-usd"
    >
      <template v-slot:prepend-item>
        <v-list-item
          ripple
          @click="toggle"
        >
          <v-list-item-action>
            <v-icon :color="myCurrencies && myCurrencies.length > 0 ? 'indigo darken-4' : ''">
              {{ icon }}
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Select All
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mt-2"></v-divider>
      </template>
    </v-select>
  </v-row>
</template>
<script>
export default {
  props:['shopId', 'stripe'],
  data(){
    return {
      myCurrencies: []
    }
  },
  watch:{
    shopId(){this.update()},
    stripe(){this.update()}
  },
  mounted(){this.update()},
  computed: {
    getMyCurrencies(){return this.stripe.currencies},
    stripeCurrencies(){return this.stripe.default.currencies},
    isAllCurrencies(){return this.myCurrencies 
      && this.myCurrencies.length 
      && this.myCurrencies.length == this.stripeCurrencies.length},
    isSomeCurrencies(){return this.myCurrencies
      && this.myCurrencies.length
      && this.myCurrencies.length > 0 && !this.isAllCurrencies},
    icon () {
      if (this.isAllCurrencies) return 'mdi-close-box'
      if (this.isSomeCurrencies) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  },
  methods: {
    update(){
      this.myCurrencies = this.stripe.currencies
    },

    toggle() {
      this.$nextTick(() => {
        if (this.isAllCurrencies) {
          this.myCurrencies = []
        } else {
          this.myCurrencies = this.stripeCurrencies.slice()
        }
        this.changedMyCurrencies()
      })
    },

    async changedMyCurrencies(){
      await this.api.post('/shop/module', {
        name: 'stripe',
        shopID: this.shopId,
        data: {currencies: this.myCurrencies}
      })
      this.$emit('refresh')
    }
  }
}
</script>