<template>
  <v-container>
    <template v-for="(item, i) in items">
      <v-list-item two-line :key="i">
        <v-list-item-action>
          <v-checkbox />
        </v-list-item-action>
        <v-list-item-content>
          <v-col>
            <v-list-item-title>{{item.name}}</v-list-item-title>
            <v-list-item-subtitle>{{item.description}}</v-list-item-subtitle>
          </v-col>
          <v-col>
            <v-sheet
              color="grey lighten-4"
              rounded
              class="pa-5"
            >
              {{item.qty}}
            </v-sheet>
          </v-col>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-container>
</template>

<script>
export default {
  props: ['items']
}
</script>
