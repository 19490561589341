const props = {
  shopId: {
    type: String,
    default: ()=>null
  },
  shop: {
    type: Object,
    default: ()=>{return {}}
  },
}

export { props }