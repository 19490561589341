<template>
  <v-expansion-panels
    v-model="panels"
    flat
    popout
    accordion
  >
    <stripe />
  </v-expansion-panels>
</template>

<script>
import Stripe from "./stripe/Index";

export default {
  components: {
    Stripe,
  },
  data() {
    return {
      panels: []
    }
  },
}
</script>
