import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
import vuetify from './plugins/vuetify';
import moment from 'moment'
import { merchbase, mbStore, mbRouter } from '@inkbird.io/vue-merchbase'
import baseModule from './base/store/module'
import UserStuff from './base/user/Index'
import AreYouSure from './shared/AreYouSure'

Vue.prototype.moment = moment 

Vue.use(Vuex)

mbStore.modules['base'] = baseModule
const store = new Vuex.Store(mbStore)

Vue.use(merchbase, { store, shopURI: 'merchbase', shopID: 'merchbase' })
Vue.config.productionTip = false

Vue.component('user-stuff', UserStuff)
Vue.component('are-you-sure', AreYouSure)

new Vue({
  store,
  vuetify,
  router: mbRouter,
  render: h => h(App)
}).$mount('#app')
