export const props = {
  shopContext: {
    type: String,
    default: ()=>null
  },
  productId: {
    type: String,
    default: ()=>null
  },
  item: {
    type: Object,
    default: ()=>{return {}}
  },
}