<template>
  <v-select
    v-model="selected"
    :items="items"
    :label="label || 'Choose'"
    :hint="hint"
    :disabled="disabled"
    chips
    multiple
    @change="$emit('changed', selected)"
  >
    <template v-slot:prepend-item>
      <v-list-item
        @click="toggle"
      >
        <v-list-item-action>
          <v-icon :color="items.length > 0 ? 'indigo darken-4' : ''">
            {{ icon }}
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            Select All
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2"></v-divider>
    </template>
  </v-select>
</template>

<script>
export default {
  props: ['items', 'label', 'hint', 'listModel', 'disabled'],
  
  data() {
    return {
      selected: []
    }
  },
  
  computed: {
    isAllSelected(){return (this.selected||[]).length ==  this.items.length},
    isSomeSelected(){return (this.selected||[]).length > 0 && !this.isAllSelected},
    icon () {
      if (this.isAllSelected) return 'mdi-close-box'
      if (this.isSomeSelected) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  },

  watch:{
    listModel(v){
      this.selected = v
    },
  },

  created(){
    this.selected = this.listModel
  },

  methods:{
    toggle () {
      this.$nextTick(() => {
        if (this.isAllSelected) {
          this.selected = []
        } else {
          this.selected = this.items.slice()
        }
      })
    },
  }
}
</script>