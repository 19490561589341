<template>
  <div
    v-if="stripe && stripe.list && stripe.list.length"
    class="pl-5 d-flex justify-start align-center"
  >
    or you may import from your
    <v-menu
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          plain
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-key-chain</v-icon>
          keychain
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in stripe.list"
          :key="index"
        >
          <v-btn
            text
            @click="confirmImport(item)"
          >
            <v-icon left>mdi-import</v-icon>
            import
            <v-chip outlined>
              <v-avatar>
                <v-icon>mdi-check</v-icon>
              </v-avatar>
              {{ item }}
            </v-chip>
          </v-btn>
        </v-list-item>
      </v-list>
    </v-menu>
    <are-you-sure
      :opt="dialog"
      @clickedNo="dialog.show=false"
      @clickedYes="importKeys()"
    />
  </div>
</template>

<script>
export default {
  props: {
    shopID: {
      type: String,
      default: null
    },
  },
  data() {
    return {
      stripe: {},
      dialog: {
        show: false,
        text: {
          title: 'Import Keys...',
        }
      },
      importAccountName: null
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    async fetch() {
      this.stripe = await this.api.get('/stripe/user')
    },
    confirmImport(accountName){
      this.dialog.text.title = `Import ${accountName} keys...`
      this.importAccountName = accountName
      this.dialog.show = true
    },
    async importKeys(){
      this.dialog.show = false
      await this.api.post('/stripe/import_keys', {
        shopID: this.shopID,
        accountName: this.importAccountName
      })
      this.$emit('refresh')
    }
  },
}
</script>