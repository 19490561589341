<template>
  <div class="d-flex align-center">
    <v-btn
      @click="toggleDarkMode()"
      text
      dense
      width="120"
    >
      <v-icon
        transition="scale-transition"
        size="120"
        style="fill: currentColor"
        :color="`${$vuetify.theme.dark ? 'white' : 'black'}`"
      >
        $mbLogo
      </v-icon>
    </v-btn>
  </div>
</template>
<script>
export default ({
  methods: {
    toggleDarkMode(){
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      this.$store.commit('base/darkmode', this.$vuetify.theme.dark)
    }
  }

})
</script>
