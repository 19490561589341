<template>
  <v-container fluid>

    <image-uploader
      :product-id="productId"
      @refresh="fetch"
    />

    <blank
      v-if="!images.length"
    />
    
    <images
      v-if="images.length"
      :product-id="productId"
      :images="images"
      @refresh="fetch"
    />

  </v-container>
</template>

<script>
import ImageUploader from './ImageUploader.vue'
import Blank from './Blank'
import Images from './Images.vue'

export default {
  components: { ImageUploader, Blank, Images },
  props: ['productId'],
  data(){
    return {
      images: []
    }
  },
  mounted(){
    this.fetch()
  },
  watch: {
    productId(){
      this.fetch()
    }
  },
  methods: {
    async fetch(){
      this.images = await this.api.get(`/product/images/${this.productId}`)
    }
  }
}
</script>