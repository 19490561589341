<template>
  <v-container fluid>

  <v-row justify="center">
    <v-expansion-panels popout>
      <v-expansion-panel
        v-for="(order, i) in orders"
        :key="i"
      >
        <v-expansion-panel-header>
          <div>{{order.shipping.email}}</div>
          <div>{{order.shipping.city}}, {{order.shipping.state}} {{order.shipping.zip}}</div>
          <template v-slot:actions>
            <v-col>
              <span id="items-complete">0/{{order.items.length}}</span>
            </v-col>
            <v-col>
              <v-row>
                <span id="age-label">
                  {{moment.unix(order.date).fromNow()}}
                </span>
              </v-row>
              <v-row>
                <v-icon>mdi-chevron-down</v-icon>
              </v-row>
            </v-col>
          </template>

        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row dense no-gutters>
            <v-col md="8">
              <order-item-control :items="order.items" />
            </v-col>
            <v-col md="4">
              <order-shipping-address :ship="order.shipping" />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-row>
  </v-container>
</template>

<script>
import orderShippingAddress from './OrderShippingAddress'
import orderItemControl from './OrderItemControl'

export default {
  components: {
    orderShippingAddress,
    orderItemControl
  },
  
  data() {
    return {
      orders: []
    }
  },
  
  created(){
    this.fetch()
  },
  methods: {
    async fetch() {
      this.orders = await this.api.get('/stripe/orders')
    }
  },

}
</script>

<style scoped>
#age-label{
  font-size: .8em;
}
</style>